const regions = document.querySelectorAll('.region');
const regionContent = document.querySelectorAll('.home--regions-region');
const regionClose = document.querySelectorAll('.map-close');
const regionBg = document.querySelector('.home--region-bg');

regions.forEach(function(region) {
    region.addEventListener('click', (reg) => {
        regionBg.classList.add('active');
        regionsReset();
        reg.currentTarget.classList.add('active');
        regionContentChange(reg.currentTarget.id);
    });
});

function regionsReset() {
    regions.forEach(function(reg) {
        reg.classList.remove('active');
    });
    regionContent.forEach(function(reg) {
        reg.classList.remove('active');
    });
}

function regionContentChange(region) {
    regionContent.forEach(function(reg) {
        if(reg.classList.contains(region)) {
            reg.classList.add('active');
        }
    });
}

regionClose.forEach(function(close) {
    close.addEventListener('click', () => {
        regionBg.classList.remove('active');
        regionsReset();
    });
});